import { Label, Wrapper } from './radio.style';

const Radio = ({ selected, text, onClick, customStyle, testId }: any) => (
  <Wrapper $customStyle={customStyle} onClick={onClick}>
    {selected ? (
      <svg
        data-testid={testId}
        className='selected'
        width="18"
        height="18"
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="9" cy="9" r="9" fill="#5850EC" />
        <path d="M4 8.5L7.33333 12L14 5" stroke="#F7F8F9" strokeWidth="2" />
      </svg>
    ) : (
      <svg
        data-testid={testId}
        width="18"
        height="18"
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="9" cy="9" r="8" stroke="#93A3BC" strokeWidth="2" />
      </svg>
    )}
    <Label title={text}>{text}</Label>
  </Wrapper>
);

export default Radio;
