import { formatDistance, formatMps, formatTimezoneDate, formatTimezoneDateFromNow } from "@/lib/formatter";
import { TriggerType } from "app/modules/detection/detection.interfaces";

/**
   * function to formatter passage time on columns
   * @param row
   * @param cell
   * @param value
   * @returns
   */
export const passageTimeFormatter = (row: number, cell: number, value: string, defs: any, context: any, grid: any) => {
  let addClasses = `gt-input fixed right _passage _passage-time _index-${row}`;
  let removeClasses = ''
  
  if (!context || !context.passage?.id || context.passage?.valid) {
    removeClasses = '_invalid';
  }

  if (context.passage && context.passage?._loaded && context.passage?.valid === false) {
    addClasses = `${addClasses} _invalid`;
  }
  
  if (grid.metadata?.permission_type !== 'editor') addClasses += ' disabled';

  let text = formatTimezoneDate({
    date: context.passage?.tstamp,
    timezone: grid.metadata?.units.timezone?.id || 'utc',
    format: 'HH:mm:ss.SSS',
  });

  if (context.passage?.invalid_reason) {
    text = `
      <div class="toltip_wrapper">
        ${text}
        <div>
          <span class="toltip">${context.passage?.invalid_reason}</span>
        </div>
      </div>
    `;
  }

  return { text, addClasses, removeClasses };
};

/**
 * function to formatter passage date on columns
 * @param row
 * @param cell
 * @param value
 * @returns
 */
export const nameFormatter = (row: number, cell: number, value: string) => {
  const addClasses = '_name';
  return { value, addClasses };
};

/**
 * function to formatter passage date on columns
 * @param row
 * @param cell
 * @param value
 * @returns
 */
export const passageDateFormatter = (row: number, cell: number, value: string, defs: any, context: any, grid: any) => {
  let addClasses = `gt-input fixed right _passage _passage-date _zindex-4 _index-${row}`;
  let removeClasses = ''

  if (!context || !context.passage?.id || context.passage?.valid) {
    removeClasses = '_invalid';
  }

  if (context.passage && context.passage?._loaded && context.passage?.valid === false) {
    addClasses = `${addClasses} _invalid`;
  }

  if (grid.metadata.permission_type !== 'editor') {
    addClasses += ' disabled';
  }

  let text = formatTimezoneDate({
    date: context.passage?.tstamp,
    timezone: grid.metadata?.units.timezone?.id || 'utc',
    format: 'YYYY-MM-DD',
  });

  if (context.passage?.invalid_reason) {
    text = `
    <div class="toltip_wrapper">
      ${text}
      <div>
        <span class="toltip">${context.passage?.invalid_reason}</span>
      </div>
    </div>
    `;
  }

  return { text, addClasses, removeClasses };
};

/**
 * function to formatter passage date on columns
 * @param row
 * @param cell
 * @param value
 * @returns
 */
export const speedFormatter = (
  row: number,
  cell: number,
  value: string,
  defs: any,
  context: any,
  grid: any
) => {
  const addClasses = `right speed-column-row-${row}-cell-${cell}`;

  let text = '';

  if (value) {
    text = formatMps({
      distance: value,
      unit: grid.metadata?.units.speed,
    }) as string;
  }

  return { text, addClasses };
};

/**
 * function to formatter passage date on columns
 * @param rowq
 * @param cell
 * @param value
 * @returns
 */
export const distanceFormatter = (
  row: number,
  cell: number,
  value: string,
  defs: any,
  context: any,
  grid: any
) => {
  const addClasses = 'right';

  let text = '';

  if (value) {
    text = formatDistance({
      distance: value,
      unit: grid.metadata?.units.distance?.id,
    }) as string;
  }

  return { text, addClasses };
};

/**
 * function to formatter eta date on columns
 * @param row
 * @param cell
 * @param value
 * @returns
 */
export const etaFormatter = (
  row: number,
  cell: number,
  value: string,
  defs: any,
  context: any,
  grid: any
) => {
  const addClasses = 'right';

  let text = '';
  if (row === 0) {
    return { text, addClasses };
  }

  const timezone = grid.metadata?.units.timezone?.id;
  if (value && timezone) {
    text = formatTimezoneDate({
      date: value,
      timezone,
      format: 'MM/DD HH:mm:ss',
    });
  }

  return { text, addClasses };
};

/**
 * function to formatter ete date on columns
 * @param row
 * @param cell
 * @param value
 * @returns
 */
export const eteFormatter = (
  row: number,
  cell: number,
  value: string,
  defs: any,
  context: any,
  grid: any
) => {
  const addClasses = `right ete-column-index-${row}`;

  let text = '';

  const timezone = grid.metadata?.units.timezone?.id;
  const launched = grid.metadata?.launched;
  if (launched && context.eta && timezone) {
    text = formatTimezoneDateFromNow({
      date: context.passage?.tstamp ? context.passage.tstamp : context.eta,
      timezone,
      isNext: context.is_next,
    });
  }

  return { text, addClasses };
};

/**
 *
 * @param row
 * @param cell
 * @param value
 * @returns
 */
export const inclinationFormatter = (row: any, cell: any, value: any): any => {
  return {
    text: value ? `${parseFloat(value)?.toFixed(1) || 0}°` : '',
    addClasses: 'fixed right',
  };
};

/**
 *
 * @param row
 * @param cell
 * @param value
 * @returns
 */
export const inputTypeFormatter = (
  row: number,
  cell: number,
  value: string,
  defs: any,
  context: any
): any => {
  let text = '-';

  const texts = {
    auto: 'AUTO',
    satellite: 'MANUAL',
  };

  const classes = {
    auto: 'auto',
    satellite: 'manual',
  };

  if (context.passage_type && texts[context.passage_type]) {
    text = `<span class=${classes[context.passage_type]}>${texts[context.passage_type]}</span>`;
  }

  return {
    text,
    addClasses: 'gt-input-type-icon',
  };
};

/**
 *
 * @param row
 * @param cell
 * @param value
 * @param defs
 * @param context
 * @returns
 */
export const triggersFormatter = (
  row: number,
  cell: number,
  value: string,
  defs: any,
  context: any,
  grid: any
) => {
  const auth = grid.metadata?.auth;
  const findTriggerNotSeen = (trigger: TriggerType) => !trigger.triggerseen_set?.find((tseen) => tseen.user === auth.user.id)
  
  const hasNewTrigger = context.trigger_set?.some(findTriggerNotSeen, false);
  const isObserver = grid?.metadata?.permission_type === 'observer';
  
  return {
    text: context.trigger_set?.length
      ? `
      <span class="gt-triggers-amount ${isObserver ? '_disabled' : ''} ${hasNewTrigger ? '_news' : ''}">${
          context.trigger_set.length
        }</span>
    `
      : '-',
    addClasses: `gt-triggers-column ${isObserver ? '_disabled' : ''}`,
  };
};
 